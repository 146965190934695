import React, { useRef, useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Box,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";

const Step1 = ({ control, setIsChecked, isChecked }) => {
  const scrollRef = useRef(null);
  const [isScrollEnd, setIsScrollEnd] = useState(false);
  const [hasAccepted, setHasAccepted] = useState(false);

  const [changelang, setChangelang] = useState("TH");

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setIsScrollEnd(true);
      } else {
        setIsScrollEnd(false);
      }
    };

    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setHasAccepted(true);
    }
  };

  useEffect(() => {
    if (!isScrollEnd && !hasAccepted) {
      setIsChecked(false);
    }
  }, [isScrollEnd, hasAccepted]);

  return (
    <div>
      <Grid container spacing={1} sx={{ marginLeft: "-5px" }}>
        <Grid item xs={12}>
          <Typography className="font-title kanit-semibold">
            กรุณาอ่านข้อความ PDPA ต่อไปนี้
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            padding: "4px 35px !important",
            justifyContent: "flex-end",
          }}
        >
          <Button
            className="change-language"
            sx={{ color: changelang === "TH" ? "#05A2F3" : "#000000" }}
            onClick={() => setChangelang("TH")}
          >
            TH
          </Button>
          <div
            style={{
              borderRight: "1px solid #D9D9D9",
              height: 30,
              marginTop: 6,
            }}
          ></div>
          <Button
            className="change-language"
            sx={{ color: changelang === "ENG" ? "#05A2F3" : "#000000" }}
            onClick={() => setChangelang("ENG")}
          >
            ENG
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ padding: "0px !important" }}>
          <Box
            sx={{
              width: {
                xs: "75%",
                sm: "75%",
                md: "85%",
                lg: "85%",
              },
              height: "100%",
              maxHeight: 300,
              // width: 200,
              overflow: "hidden",
              border: "1px solid #F0F0F0",
              borderRadius: "1.6rem",
              padding: 1,
              margin: "auto",

              position: "relative",
            }}
          >
            <Box
              sx={{
                height: "100%",
                overflow: "auto",
                // margin: 0,
                // padding: 0,
                // padding: "0.2rem",
                // clipPath: "inset(0 round 1rem)",
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#555",
                  },
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#f0f0f0",
                },
              }}
              ref={scrollRef}
            >
              {changelang === "TH" ? (
                <Box sx={{ padding: "16px 20px" }}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontFamily: '"Kanit", sans-serif',
                      fontSize: "2.25rem",
                      color: "#05A2F3",
                    }}
                  >
                    หนังสือขอความยินยอมสำหรับลูกค้า คู่ค้า และ/หรือ
                    คู่สัญญาทางธุรกิจ
                  </Typography>

                  <Typography
                    paragraph
                    sx={{
                      fontFamily: '"Kanit", sans-serif',
                      fontSize: "1.5rem",
                      paddingTop: "12px",
                    }}
                  >
                    โปรดอ่าน <em>ประกาศความเป็นส่วนตัวสำหรับลูกค้า</em>
                    ก่อนลงนามให้ความยินยอมแก่บริษัทในการเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน
                    เพื่อให้เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.
                    2562 และกฎหมายอื่น ๆ ที่เกี่ยวข้อง
                    รวมถึงกฎหมายฉบับแก้ไขเพิ่มเติมใด ๆ ในอนาคต
                    (“กฎหมายคุ้มครองข้อมูลส่วนบุคคล”) บริษัท เอส.เอ็ม.ซี.
                    (ประเทศไทย) จำกัด (“บริษัท”)
                    จึงประสงค์จะขอรับความยินยอมจากท่านในการเก็บรวบรวมข้อมูลส่วนบุคคลของท่านเพื่อการนำไปใช้และเปิดเผยตามวัตถุประสงค์และรายละเอียดในหนังสือขอความยินยอมสำหรับลูกค้า
                    คู่ค้า และ/หรือ คู่สัญญาทางธุรกิจ
                  </Typography>

                  <Typography
                    paragraph
                    sx={{
                      fontFamily: '"Kanit", sans-serif',
                      fontSize: "1.5rem",
                    }}
                  >
                    อย่างไรก็ดี ภายหลังจากที่ท่านได้ให้ความยินยอมแก่บริษัทแล้ว
                    ท่านสามารถถอนความยินยอมได้ทุกเมื่อ
                    การถอนความยินยอมดังกล่าวย่อมไม่มีผลกระทบต่อการดำเนินการเกี่ยวกับข้อมูลส่วนบุคคลใด
                    ๆ ที่กระทำไปแล้วก่อนการถอนความยินยอมนั้น
                  </Typography>

                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: '"Kanit", sans-serif',
                      fontWeight: "bold",
                      fontSize: "1.75rem",
                      marginTop: 2,
                    }}
                  >
                    ข้าพเจ้า
                    ยินยอมให้บริษัทเก็บรวบรวมข้อมูลส่วนบุคคลของข้าพเจ้าเพื่อวัตถุประสงค์ดังต่อไปนี้
                    :
                  </Typography>

                  <Box sx={{ marginLeft: 2 }}>
                    <Typography
                      paragraph
                      sx={{
                        fontFamily: '"Kanit", sans-serif',
                        fontSize: "1.5rem",
                      }}
                    >
                      <strong style={{ marginRight: 4 }}>
                        ยินยอมให้บริษัทเก็บรวบรวมข้อมูลส่วนบุคคลที่มีความอ่อนไหว
                      </strong>
                      ที่ปรากฏบนสำเนาบัตรประชาชนหรือเอกสารแสดงตัวตนอื่นของข้าพเจ้า
                      เพื่อระบุตัวตนในการเข้าทำสัญญาและปฏิบัติตามสัญญา
                    </Typography>

                    <Typography
                      paragraph
                      sx={{
                        fontFamily: '"Kanit", sans-serif',
                        fontSize: "1.5rem",
                      }}
                    >
                      <strong style={{ marginRight: 4 }}>
                        ยินยอมให้บริษัทเก็บรวบรวมข้อมูลส่วนบุคคลที่มีความอ่อนไหว
                      </strong>
                      ของข้าพเจ้า อาทิ ศาสนา หมู่โลหิต
                      ที่ปรากฏบนสําเนาบัตรประชาชนหรือเอกสารแสดงตัวตนอื่นของข้าพเจ้า
                      รวมถึงเปิดเผยต่อบุคคลภายนอก ได้แก่ หน่วยงานของรัฐ
                      ที่ปรึกษากฎหมาย เป็นต้น
                      เพื่อใช้ระบุตัวตนและอำนาจของข้าพเจ้าในการเข้าทำสัญญาและปฏิบัติตามสัญญา
                      ตลอดจนการเรียกเก็บค่าสินค้าและค่าบริการภายใต้สัญญา
                    </Typography>

                    <Typography
                      paragraph
                      sx={{
                        fontFamily: '"Kanit", sans-serif',
                        fontStyle: "italic",
                        fontSize: "1.5rem",
                      }}
                    >
                      กรณีที่ท่านไม่ให้ความยินยอมในการเก็บรวบรวมข้อมูลศาสนาและหมู่โลหิตซึ่งปรากฏบนสำเนาบัตรประชาชนหรือเอกสารแสดงตัวตนอื่น
                      กรุณาขีดฆ่าข้อมูลดังกล่าวออกจากเอกสารก่อนนำส่งให้บริษัท
                    </Typography>

                    <Typography
                      paragraph
                      sx={{
                        fontFamily: '"Kanit", sans-serif',
                        fontSize: "1.5rem",
                      }}
                    >
                      <strong style={{ marginRight: 4 }}>
                        ยินยอมให้บริษัทเก็บรวบรวมสำเนาบัตรประชาชน
                      </strong>
                      และเปิดเผยต่อลูกค้าของบริษัท
                      เพื่อยืนยันตัวตนของข้าพเจ้าในการส่งสินค้าของบริษัทให้กับลูกค้า
                      หรือเพื่อการขออนุญาตเข้าไปทำงานในสถานที่ของลูกค้า
                    </Typography>

                    <Typography
                      paragraph
                      sx={{
                        fontFamily: '"Kanit", sans-serif',
                        fontStyle: "italic",
                        fontSize: "1.5rem",
                      }}
                    >
                      กรุณาขีดฆ่าข้อมูลศาสนาและหมู่โลหิตซึ่งปรากฏบนสำเนาบัตรประชาชนหรือเอกสารแสดงตัวตนอื่นก่อนน่าส่งเอกสารให้บริษัท
                    </Typography>

                    <Typography
                      paragraph
                      sx={{
                        fontFamily: '"Kanit", sans-serif',
                        fontSize: "1.5rem",
                      }}
                    >
                      <strong style={{ marginRight: 4 }}>
                        ยินยอมให้บริษัทเก็บรวบรวมข้อมูลสุขภาพของข้าพเจ้า
                      </strong>
                      เช่น ผลการตรวจโรค และเปิดเผยให้ลูกค้าของบริษัท
                      ตามที่ลูกค้าบริษัทร้องขอ
                      เพื่อใช้ในการขออนุญาตเข้าไปทำงานในสถานที่ของลูกค้า
                    </Typography>
                  </Box>

                  <Typography
                    paragraph
                    sx={{
                      fontFamily: '"Kanit", sans-serif',
                      fontSize: "1.5rem",
                    }}
                  >
                    <strong style={{ marginRight: 4 }}>เพื่อเป็นหลักฐาน</strong>
                    ข้าพเจ้าจึงได้ลงนามด้วยความสมัครใจและโดยอิสระเพื่อให้ความยินยอมแก่บริษัทในการเก็บรวบรวม
                    ใช้ เปิดเผย
                    ข้อมูลส่วนบุคคลของข้าพเจ้าตามวัตถุประสงค์ดังกล่าวที่ระบุไว้ในหนังสือขอความยินยอมฉบับนี้
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ padding: "16px 80px" }}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontFamily: '"Kanit", sans-serif',
                      fontSize: "2.25rem",
                      color: "#05A2F3",
                    }}
                  >
                    Letter of Consent for Customers, Vendors, and/or Business
                    Partners
                  </Typography>

                  <Typography
                    paragraph
                    sx={{
                      fontFamily: '"Kanit", sans-serif',
                      fontSize: "1.5rem",
                      paddingTop: "12px",
                    }}
                  >
                    Please read the Privacy Notice for Customers, vendors,
                    and/or business partners before giving consent to the
                    Company to collect your Personal Data. Pursuant to the
                    Personal Data Protection Act B.E. 2562 and other related
                    laws, including any further amendments thereof ("PDPA"), SMC
                    (Thailand) Ltd. (the "Company") seeks to obtain your consent
                    to collect Personal Data to use and disclose according to
                    the objectives and details stipulated in the Privacy Notice
                    for Customers, vendors, and/or business partners.
                  </Typography>

                  <Typography
                    paragraph
                    sx={{
                      fontFamily: '"Kanit", sans-serif',
                      fontSize: "1.5rem",
                    }}
                  >
                    Nonetheless, after you have given your consent, you may
                    withdraw your consent at any time. The withdrawal of your
                    consent shall not affect any Personal Data which has already
                    been processed prior to you withdrawing your consent.
                  </Typography>

                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: '"Kanit", sans-serif',
                      fontWeight: "bold",
                      fontSize: "1.75rem",
                      marginTop: 2,
                    }}
                  >
                    I, as the data subject, hereby give consent to the Company
                    to collect my Personal Data for the following purposes :
                  </Typography>

                  <Box sx={{ marginLeft: 4 }}>
                    <Typography
                      paragraph
                      sx={{
                        fontFamily: '"Kanit", sans-serif',
                        fontSize: "1.5rem",
                      }}
                    >
                      <strong style={{ marginRight: 4 }}>
                        The Company can collect my Sensitive Personal Data
                      </strong>
                      as appeared on the copy of identification card or other
                      identity documents for entering into or performing the
                      contract.
                    </Typography>

                    <Typography
                      paragraph
                      sx={{
                        fontFamily: '"Kanit", sans-serif',
                        fontSize: "1.5rem",
                      }}
                    >
                      <strong style={{ marginRight: 4 }}>
                        The Company can collect my Sensitive Personal Data,
                      </strong>
                      e.g., religion, blood group as appeared on the copy of the
                      identification card or other identity documents, and
                      disclose to third parties, e.g., government authorities,
                      legal consultants, for entering into or performing the
                      contract including the collection of selling price or
                      service fees under the contract.
                    </Typography>

                    <Typography
                      paragraph
                      sx={{
                        fontFamily: '"Kanit", sans-serif',
                        fontStyle: "italic",
                        fontSize: "1.5rem",
                      }}
                    >
                      (In the event that you do not give consent for the
                      collection of religion and blood group data on the copy of
                      identification card or other identity documents, please
                      cross out such data before sending the documents to the
                      Company)
                    </Typography>

                    <Typography
                      paragraph
                      sx={{
                        fontFamily: '"Kanit", sans-serif',
                        fontSize: "1.5rem",
                      }}
                    >
                      <strong style={{ marginRight: 4 }}>
                        The Company can collect my copy of identification card
                      </strong>
                      and disclose it to the Company's customers for identity
                      verification and approval.
                    </Typography>

                    <Typography
                      paragraph
                      sx={{
                        fontFamily: '"Kanit", sans-serif',
                        fontSize: "1.5rem",
                      }}
                    >
                      <strong style={{ marginRight: 4 }}>
                        I consent that the Company can collect copy of my
                        identification card
                      </strong>
                      and disclose it to the Company's customers for identifying
                      myself and my authority as the delivery agent of goods of
                      the Company or for obtaining an approval from the customer
                      to enter the customer's site and provide services as
                      agreed with the Company.
                    </Typography>

                    <Typography
                      paragraph
                      sx={{
                        fontFamily: '"Kanit", sans-serif',
                        fontStyle: "italic",
                        fontSize: "1.5rem",
                      }}
                    >
                      (Please cross out the religion and blood group data on the
                      copy of identification card before sending the documents
                      to the Company. However, in case you do not give consent
                      to the Company for the collection, the Company's customers
                      may request for your identity documents from you
                      directly.)
                    </Typography>

                    <Typography
                      paragraph
                      sx={{
                        fontFamily: '"Kanit", sans-serif',
                        fontSize: "1.5rem",
                      }}
                    >
                      <strong style={{ marginRight: 4 }}>
                        The Company can collect my health information
                      </strong>
                      and disclose it to the Company's customers for obtaining
                      approval to enter the customer's site.
                    </Typography>
                  </Box>

                  <Typography
                    paragraph
                    sx={{
                      fontFamily: '"Kanit", sans-serif',
                      fontSize: "1.5rem",
                    }}
                  >
                    <strong z>
                      I consent that the Company can collect copy of my health
                      information,
                    </strong>
                    e.g., health examination result, and disclose it to the
                    Company's customer in order to obtain an approval from the
                    customer to enter the customer's site and provide services
                    as agreed with the Company.
                  </Typography>

                  <Typography
                    paragraph
                    sx={{
                      fontFamily: '"Kanit", sans-serif',
                      fontSize: "1.5rem",
                    }}
                  >
                    I hereby sign this letter voluntarily and independently as
                    evidence to allow the Company to collect, use, and disclose
                    my Personal Data for the objectives stipulated in this
                    Letter of Consent.
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ margin: 0, padding: "0px !important" }}>
          <FormControlLabel
            sx={{
              ".MuiTypography-root": {
                fontSize: "1.2rem",
                fontFamily: "Kanit , sans-serif",
                fontWeight: 400,
              },
              ".MuiSvgIcon-root": {
                fontSize: "2.4rem",
                fontFamily: "Kanit , sans-serif",
                fontWeight: 400,
              },
            }}
            control={
              <Checkbox
                disabled={!isScrollEnd && !hasAccepted}
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            }
            label="ฉันได้อ่านและยอมรับเงื่อนไข PDPA"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Step1;
