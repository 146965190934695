import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Card,
  Container,
  CardContent,
  CardActions,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";
import { api } from "../api/index";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate } from "react-router-dom";

const steps = ["PDPA", "ข้อมูลส่วนบุคคล", "ข้อมูลการติดต่อ"];

const schemaStep1 = yup.object().shape({
  // idNumber: yup.string().required("กรุณากรอกเลขประจำตัวประชาชนหรือ Passport"),
});

const schemaStep2 = yup.object().shape({
  personal_id: yup
    .string()
    .required("จำเป็นต้องกรอก")
    .matches(/^[A-Za-z0-9]+$/, "กรุณากรอกเป็นภาษาอังกฤษและตัวเลขเท่านั้น")
    .max(13, "กรุณากรอกไม่เกิน 13 หลัก"),
  first_name: yup
    .string()
    .required("จำเป็นต้องกรอก")
    .matches(/^[\p{L}\p{M}\s]+$/u, "กรุณากรอกเป็นตัวอักษรเท่านั้น"),
  last_name: yup
    .string()
    .required("จำเป็นต้องกรอก")
    .matches(/^[\p{L}\p{M}\s]+$/u, "กรุณากรอกเป็นตัวอักษรเท่านั้น"),
  tel_no: yup
    .string()
    .nullable()
    .required("จำเป็นต้องกรอก")
    .matches(/^\d+$/, {
      message: "กรุณากรอกเป็นตัวเลขเท่านั้น",
      excludeEmptyString: true,
    })
    .max(10, "กรุณากรอกไม่เกิน 10 หลัก"),
  email: yup
    .string()
    .email("กรุณากรอกเป็นอีเมลเท่านั้น")
    .nullable("จำเป็นต้องกรอก"),
  license_plate: yup.string().nullable(),
  company_name: yup.string().required("จำเป็นต้องกรอก"),
  // license_plate_province: yup.string().when("personal_id", (personal_id, schema) => {
  //   const license_plate = yup.ref("license_plate");
  //   if (license_plate) {
  //     return schema
  //       .required("จำเป็นต้องกรอก")
  //   } else {
  //     return schema.nullable();
  //   }
  // }),
  // vehicle_type_id: yup.string().when("personal_id", (personal_id, schema) => {
  //   const license_plate = yup.ref("license_plate");
  //   console.log(license_plate);

  //   if (license_plate) {
  //     return schema
  //       .required("จำเป็นต้องกรอก")
  //   } else {
  //     return schema.nullable();
  //   }
  // }),
  followers: yup.array().of(
    yup.object().shape({
      personal_id: yup
        .string()
        .required("จำเป็นต้องกรอก")
        .matches(/^[A-Za-z0-9]+$/, "กรุณากรอกเป็นภาษาอังกฤษและตัวเลขเท่านั้น")
        .max(13, "กรุณากรอกไม่เกิน 13 หลัก"),
      first_name: yup
        .string()
        .required("จำเป็นต้องกรอก")
        .matches(/^[\p{L}\p{M}\s]+$/u, "กรุณากรอกเป็นตัวอักษรเท่านั้น"),
      last_name: yup
        .string()
        .required("จำเป็นต้องกรอก")
        .matches(/^[\p{L}\p{M}\s]+$/u, "กรุณากรอกเป็นตัวอักษรเท่านั้น"),
      tel_no: yup
        .string()
        .nullable()
        .required("จำเป็นต้องกรอก")
        .matches(/^\d+$/, {
          message: "กรุณากรอกเป็นตัวเลขเท่านั้น",
          excludeEmptyString: true,
        })
        .max(10, "กรุณากรอกไม่เกิน 10 หลัก"),
      email: yup
        .string()
        .email("กรุณากรอกเป็นอีเมลเท่านั้น")
        .nullable("จำเป็นต้องกรอก"),
      license_plate: yup.string().nullable(),
      company_name: yup.string().required("จำเป็นต้องกรอก"),
    })
  ),
});

const schemaStep3 = yup.object().shape({
  detail: yup.string().required("จำเป็นต้องกรอก"),
  department: yup.object().required("จำเป็นต้องกรอก"),
  // startdate: yup
  //   .date()
  //   .typeError("กรุณาระบุวันที่ให้ถูกต้อง")
  //   .required("จำเป็นต้องกรอก")
  //   .test(
  //     "Startdate-Enddate",
  //     "วันที่เริ่มต้นต้องไม่เกินวันที่สิ้นสุด",
  //     function (value) {
  //       const { enddate } = this.parent;
  //       return !enddate || value <= enddate;
  //     }
  //   ),
  startdate: yup
    .mixed()
    .test("is-date", "กรุณาระบุวันที่ให้ถูกต้อง", (value) =>
      moment(value, "DD/MM/YYYY", true).isValid()
    )
    .required("จำเป็นต้องกรอก")
    .test(
      "Startdate-Enddate",
      "วันที่เริ่มต้นต้องไม่เกินวันที่สิ้นสุด",
      function (value) {
        const { enddate } = this.parent;
        const startDate = moment(value, "DD/MM/YYYY", true);
        const endDate = moment(enddate, "DD/MM/YYYY", true);
        return (
          startDate.isValid() &&
          (!enddate || (endDate.isValid() && startDate.isSameOrBefore(endDate)))
        );
      }
    ),
  // enddate: yup
  //   .date()
  //   .typeError("กรุณาระบุวันที่ให้ถูกต้อง")
  //   .required("จำเป็นต้องกรอก"),
  enddate: yup
    .mixed()
    .test("is-date", "กรุณาระบุวันที่ให้ถูกต้อง", (value) =>
      moment(value, "DD/MM/YYYY", true).isValid()
    )
    .required("จำเป็นต้องกรอก")
    .test(
      "Enddate-Startdate",
      "วันที่สิ้นสุดต้องไม่ก่อนวันที่เริ่มต้น",
      function (value) {
        const { startdate } = this.parent;
        const startDate = moment(startdate, "DD/MM/YYYY", true);
        const endDate = moment(value, "DD/MM/YYYY", true);
        return (
          endDate.isValid() &&
          (!startdate ||
            (startDate.isValid() && endDate.isSameOrAfter(startDate)))
        );
      }
    ),
});

const getSchema = (step) => {
  switch (step) {
    case 0:
      return schemaStep1;
    case 1:
      return schemaStep2;
    case 2:
      return schemaStep3;
    default:
      return schemaStep1;
  }
};

const RegistrationForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [followers, setFollowers] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invitetoken = location?.state?.sn || queryParams.get("invite_token");
  const [formtoken, setFormtoken] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const formatDate = (isoString) => {
    if (!isoString) return "";
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const StyledCard = styled(Card)(({ theme }) => ({
    height: "60vh",
    [theme.breakpoints.down("md")]: {
      height: "70vh",
    },
    position: "relative",
  }));

  const methods = useForm({
    resolver: yupResolver(getSchema(activeStep)),
    defaultValues: { license_plate: "" },
    mode: "onChange",
  });
  const { control, formState, watch, setValue, handleSubmit, reset } = methods;
  const { errors, isSubmitting } = formState;
  const contactListValue = methods.watch("contact_list");

  const handleNext = async (data) => {
    console.log(data);
    if (activeStep === 0 && !isChecked) {
      Swal.fire({
        icon: "error",
        // title: "Oops...",
        text: "กรุณาอ่านและ ยอมรับเงื่อนไข PDPA ก่อนดำเนินการต่อ",
      });
      return;
    }
    if (activeStep === steps.length - 1) {
      setIsLoading(true);
      const formtokenData = formtoken || {};
      const dummy = {
        ...data,
        obj_id: data?.object?.id,
        objective: data?.object?.reg_obj_name,
        // area_id: data?.area_id?.map((e) => e.id) || null,
        area_id:
          formtokenData.area_id || data?.area_id?.map((e) => e.id) || null,
        contact_personal_id: data?.contactPerson?.personal_id || null,
        contact_person_id: data?.contactPerson?.id || null,
        department_id: data?.department?.id || null,
        company_id: data?.department?.company_id || null,
        // startdate:
        //   (data?.startdate
        //     ? moment(data.startdate).format("YYYY-MM-DD")
        //     : moment().format("YYYY-MM-DD")) + " 00:00:00",
        // enddate:
        //   (data?.enddate
        //     ? moment(data.enddate).format("YYYY-MM-DD")
        //     : moment().format("YYYY-MM-DD")) + " 23:59:59",
        startdate: moment(data.startdate, "DD/MM/YYYY")
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss"),
        enddate: moment(data.enddate, "DD/MM/YYYY")
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss"),

        followers: data.followers || [],
        invite_id: formtoken?.id,
        invite_token: invitetoken,
      };
      const response1 = await api
        .post(
          `/online_register/invite`,
          dummy,
          // {
          //   params: {
          //     invite_id: formtoken?.id,
          //     invite_token: invitetoken,
          //   },
          // },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (ret) => {
          Swal.fire({
            icon: "success",
            title: "สำเร็จ",
            text: "ข้อมูลของคุณถูกส่งเรียบร้อยแล้ว",
          });
          setIsFormSubmitted(true);
          setActiveStep(0);
          reset({});
        })

        .catch(function (error) {
          Swal.fire({
            icon: "error",
            title: "ไม่สำเร็จ",
            text: "ไม่สามารถส่งข้อมูลได้ กรุณาลองใหม่อีกครั้ง",
          });
        })
        .finally(() => setIsLoading(false));

      // const response1 = await api
      //   .post(`/online_regiงster`, dummy, {
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   })
      //   .then(async (ret) => {
      //     Swal.fire({
      //       icon: "success",
      //       title: "สำเร็จ",
      //       text: "ข้อมูลของคุณถูกส่งเรียบร้อยแล้ว",
      //     });
      //     setIsFormSubmitted(true);
      //     setActiveStep(0);
      //     reset({});
      //   })

      //   .catch(function (error) {
      //     Swal.fire({
      //       icon: "error",
      //       title: "ไม่สำเร็จ",
      //       text: "ไม่สามารถส่งข้อมูลได้ กรุณาลองใหม่อีกครั้ง",
      //     });
      //   })
      //   .finally(() => setIsLoading(false));
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleRefresh = async () => {
  //   setIsLoading(true);
  //   if (invitetoken) {
  //     if (invitetoken.length < 5) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "ลิงค์การเชิญนี้ไม่ถูกต้อง หรือ<br>มีการลงทะเบียนแล้ว",
  //         text: "กรุณาติดต่อเจ้าหน้าที่",
  //         showConfirmButton: true,
  //         confirmButtonText: "OK",
  //         allowOutsideClick: false,
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           Swal.close();
  //           navigate("/");
  //         }
  //       });
  //       setTimeout(() => {
  //         Swal.close();
  //         navigate("/");
  //       }, 5000);
  //       setIsLoading(false);
  //       return;
  //     }
  //     try {
  //       const response = await api.get(`/online_register/invite`, {
  //         params: {
  //           invite_token: invitetoken,
  //         },
  //       });

  //       if (
  //         !response?.data?.data?.[0] ||
  //         response?.data?.total === 0
  //         // || response?.data?.data?.[0].invite_status === 1
  //       ) {
  //         Swal.fire({
  //           icon: "error",
  //           title: "ลิงค์การเชิญนี้ไม่ถูกต้อง หรือ<br>มีการลงทะเบียนแล้ว",
  //           // html: "ลิงค์การเชิญนี้ไม่ถูกต้อง<br>หรือ<br>มีการลงทะเบียนแล้ว",
  //           text: "กรุณาติดต่อเจ้าหน้าที่",
  //           showConfirmButton: true,
  //           confirmButtonText: "OK",
  //           allowOutsideClick: false,
  //         }).then((result) => {
  //           if (result.isConfirmed) {
  //             Swal.close();
  //             navigate("/");
  //           }
  //         });
  //         setTimeout(() => {
  //           Swal.close();
  //           navigate("/");
  //         }, 5000);

  //         setIsLoading(false);
  //       } else {
  //         setFormtoken(response?.data?.data?.[0]);
  //         setIsLoading(false);
  //       }
  //     } catch (error) {
  //       // console.error("Error fetching data:", error);
  //       setIsLoading(false);
  //     }
  //   } else {
  //     navigate("/");
  //     setIsLoading(false);
  //   }
  // };

  const handleRefresh = async () => {
    setIsLoading(true);
    if (invitetoken) {
      if (invitetoken.length < 5) {
        Swal.fire({
          icon: "error",
          title: "ลิงค์การเชิญนี้ไม่ถูกต้อง หรือ<br>มีการลงทะเบียนแล้ว",
          text: "กรุณาติดต่อเจ้าหน้าที่",
          showConfirmButton: true,
          confirmButtonText: "OK",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.close();
            navigate("/");
          }
        });
        setTimeout(() => {
          Swal.close();
          navigate("/");
        }, 5000);
        setIsLoading(false);
        return;
      }
      try {
        const response = await api.get(`/online_register/invite`, {
          params: {
            invite_token: invitetoken,
          },
        });

        const inviteData = response?.data?.data?.[0];

        if (!inviteData || response?.data?.total === 0) {
          Swal.fire({
            icon: "error",
            title: "ลิงค์การเชิญนี้ไม่ถูกต้อง หรือ<br>มีการลงทะเบียนแล้ว",
            text: "กรุณาติดต่อเจ้าหน้าที่",
            showConfirmButton: true,
            confirmButtonText: "OK",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.close();
              navigate("/");
            }
          });
          setTimeout(() => {
            Swal.close();
            navigate("/");
          }, 5000);

          setIsLoading(false);
          return;
        }

        // ตรวจสอบ end_date
        const currentDate = new Date(); // วันที่ปัจจุบัน
        const endDate = new Date(inviteData.end_date); // end_date จาก response

        // ดึงเฉพาะวันที่ (รูปแบบ YYYY-MM-DD) มาเปรียบเทียบ
        const currentDateString = currentDate.toISOString().split("T")[0];
        const endDateString = endDate.toISOString().split("T")[0];

        // ถ้า end_date น้อยกว่าวันปัจจุบัน
        if (endDateString < currentDateString) {
          Swal.fire({
            icon: "error",
            title: "เลยเวลาลงทะเบียนแล้ว",
            text: "กรุณาติดต่อเจ้าหน้าที่",
            showConfirmButton: true,
            confirmButtonText: "OK",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.close();
              navigate("/");
            }
          });
          setTimeout(() => {
            Swal.close();
            navigate("/");
          }, 5000);

          setIsLoading(false);
          return;
        }

        // ถ้า end_date ยังไม่หมดอายุ (>= ปัจจุบัน)
        setFormtoken(inviteData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      navigate("/");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // console.log(formtoken);
  }, [formtoken]);

  useEffect(() => {
    handleRefresh();
  }, []);

  const onSubmit = (data) => {
    handleNext(data);
  };

  return (
    <div className="bg">
      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          bgcolor="rgba(255, 255, 255, 0.7)"
          zIndex={1300}
        >
          <CircularProgress size={80} />
        </Box>
      )}
      <FormProvider {...methods}>
        <Container className="content-center pt-32">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className="kanit-bold"
              sx={{
                // display: {
                //   xs: "none",
                //   sm: "flex",
                // },
                "@media (max-width: 375px)": {
                  display: "none",
                },
                ".MuiStepLabel-label": {
                  fontSize: "1.6rem",
                  color: "#9CA3AF",
                  fontFamily: "Kanit , sans-serif",
                  fontWeight: 500,
                  marginTop: "8px !important",
                },

                ".MuiStepLabel-label.Mui-active": {
                  color: "#05A2F3",
                },
                ".MuiStepLabel-label.Mui-completed": {
                  color: "#05A2F3",
                },
                ".MuiStepIcon-root": {
                  color: "#9CA3AF",
                  fontSize: "2.4rem",
                },
                ".MuiStepIcon-root.Mui-active": {
                  color: "#05A2F3",
                },
                ".MuiStepIcon-root.Mui-completed": {
                  color: "#05A2F3",
                },
              }}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel className="font-title">{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Card
              className="mt-16 h-65 shadow rounded-2xl "
              sx={{
                position: "relative",
                "@media (max-width: 375px)": {
                  height: "80vh",
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  textAlign: "center",
                  padding: 0,
                }}
              >
                {activeStep === 0 && (
                  <Step1
                    control={methods.control}
                    setIsChecked={setIsChecked}
                  />
                )}
                {activeStep === 1 && (
                  <Step2
                    control={methods.control}
                    setValue={methods.setValue}
                    watch={methods.watch}
                    formState={methods.formState}
                    followers={followers}
                    setFollowers={setFollowers}
                  />
                )}
                {activeStep === 2 && (
                  <Step3
                    // control={methods.control}
                    // watch={methods.watch}
                    // formState={methods.formState}
                    // followers={followers}
                    // setFollowers={setFollowers}
                    // contactListValue={contactListValue}
                    formtoken={formtoken}
                  />
                )}
              </CardContent>
              <CardActions
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  padding: "0px",
                  margin: 0,
                  width: "100%",
                  background: "#ffffff",
                  zIndex: 10,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    margin: 1.5,
                    background: "#ffffff",
                    zIndex: 10,
                  }}
                >
                  <Button
                    className={activeStep === 0 ? "hidden bt-back" : "bt-back"}
                    variant="outlined"
                    onClick={handleBack}
                    disabled={isSubmitting}
                  >
                    ย้อนกลับ
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    className="bt-submit"
                    disabled={isSubmitting}
                  >
                    {activeStep === steps.length - 1 ? "สำเร็จ" : "ถัดไป"}
                  </Button>
                </Box>
              </CardActions>
            </Card>
          </form>
        </Container>
      </FormProvider>
    </div>
  );
};

export default RegistrationForm;
