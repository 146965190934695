import React, { useRef, useState, useEffect, createRef } from "react";
import {
  TextField,
  Grid,
  Typography,
  IconButton,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  createFilterOptions,
  Card,
  CardContent,
  Collapse,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import { Controller, useFormContext } from "react-hook-form";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/system/Box";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import styled from "styled-components";
import { api, apiOLR } from "../api/index";

import provincesData from "./store/province.json";

const Step2 = ({ setValue, watch, formState, followers, setFollowers }) => {
  const { control } = useFormContext();
  const ref = useRef();
  const { errors } = formState;
  const contactPersons = ["Mr. A", "Ms. B", "Dr. C"];
  const mainImageRef = useRef(null);

  const [provinces, setProvinces] = useState([]);
  const [vehicleType, setVehicleType] = useState([]);

  const followerRefs = useRef([]);

  const [expands, setExpands] = useState({});
  useEffect(() => {
    if (provincesData && provincesData.provinces) {
      setProvinces(provincesData.provinces);
    }
    handleRefresh();
  }, []);
  async function handleRefresh(data) {
    const response = await api.get(
      `/online_register/vehicle_type`,
      {
        params: {
          sort: "asc",
          order_by: "vehicle_type_name",
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setVehicleType(response?.data?.data || []);
  }
  // const addFollower = () => {
  //   setFollowers((prevFollowers) => [...prevFollowers, {}]);
  // };

  const removeFollower = (index) => {
    setFollowers((prevFollowers) =>
      prevFollowers.filter((_, i) => i !== index)
    );
    setValue(
      `followers`,
      watch("followers").filter((_, i) => i !== index)
    );
  };

  const toggleExpands = (index) => {
    const isExpand = expands[index] ? expands[index] : false;
    setExpands({ ...expands, [index]: !isExpand });
  };

  const addFollower = () => {
    setFollowers((prevFollowers) => {
      const updatedFollowers = [...prevFollowers, {}];

      setTimeout(() => {
        const newFollowerIndex = updatedFollowers.length - 1;
        const followerElement = followerRefs.current[newFollowerIndex];

        if (followerElement) {
          followerElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });

          toggleExpands(newFollowerIndex + 1);
        }
      }, 100);

      return updatedFollowers;
    });
  };

  const handleImageUpload = (e, onChange) => {
    const file = e?.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        onChange(base64String);
        setValue("trash_files", true);
      };
      reader.readAsDataURL(file); // แปลงไฟล์เป็น Base64
    }
  };

  return (
    <>
      <div className=" w-full">
        <Box className="plr-16" sx={{ textAlign: " right" }}>
          <Button
            type="submit"
            variant="contained"
            className="bt-add white-space"
            onClick={addFollower}
            startIcon={<AddIcon />}
          >
            เพิ่มผู้ติดตาม
          </Button>
        </Box>
      </div>
      <div className="p-16 w-full">
        <Box
          sx={{
            maxHeight: 450,
            overflowY: "auto",
            paddingLeft: 4,
            paddingRight: 4,
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#555",
              },
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f0f0f0",
            },
          }}
        >
          <Card sx={{ marginBottom: 3, border: 1, borderColor: "#E5E5E5" }}>
            <CardContent
              sx={{
                p: 2,
                "&:last-child": {
                  paddingBottom: 2,
                },
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs={12}>
                  <Typography className=" tx-left font-title1 kanit-semibold p-8">
                    ผู้ติดต่อหลัก
                  </Typography>
                </Grid>
              </Grid>

              <Box className="pbt-8">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} className="display-center">
                    <div class="container clearfix">
                      <div className="w-full">
                        <div className="flex flex-auto items-center pb-10 display-center">
                          <div className="border-box-img p-16">
                            <Typography className="text-position kanit-semibold font-14">
                              รูปโปรไฟล์
                            </Typography>
                            <Controller
                              control={control}
                              name="image"
                              render={({ field: { onChange, value } }) => (
                                <Box
                                  sx={{
                                    border: "1px solid rgba(0, 0, 0, 0.23)",
                                  }}
                                  className="relative flex items-center justify-center w-170 h-170 rounded-10 overflow-hidden"
                                >
                                  <div className="absolute inset-0 bg-black bg-opacity-50 " />
                                  <input
                                    accept="image/*"
                                    className="hidden"
                                    ref={mainImageRef}
                                    type="file"
                                    onChange={(e) =>
                                      handleImageUpload(e, onChange)
                                    }
                                  />

                                  <div className="absolute inset-0 flex items-center justify-center z-20">
                                    <Button
                                      variant="contained"
                                      className="bt-upload"
                                      onClick={() =>
                                        mainImageRef.current.click()
                                      }
                                    >
                                      อัปโหลดรูป
                                    </Button>
                                  </div>

                                  <Avatar
                                    sx={{
                                      position: "absolute",
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: "0px",
                                      color: "text.secondary",
                                    }}
                                    src={value ? value : null}
                                    variant="rounded"
                                  />
                                </Box>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} className="mb-20">
                        <Controller
                          name="personal_id"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              InputLabelProps={{
                                // shrink: true,
                                style: {
                                  fontSize: "16px",
                                  fontFamily: "Kanit, sans-serif",
                                },
                              }}
                              sx={{
                                ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                  {
                                    color: "red",
                                  },
                                "& .MuiInputBase-root": {
                                  fontSize: "16px",
                                  fontFamily: "Kanit, sans-serif",
                                },
                              }}
                              {...field}
                              error={!!errors.personal_id}
                              helperText={errors?.personal_id?.message}
                              required
                              label="เลขบัตรประชาชน/พาสปอร์ต"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} className="mb-20 ">
                        <Controller
                          name="first_name"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              InputLabelProps={{
                                // shrink: true,
                                style: {
                                  fontSize: "16px",
                                  fontFamily: "Kanit, sans-serif",
                                },
                              }}
                              sx={{
                                ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                  {
                                    color: "red",
                                  },
                                "& .MuiInputBase-root": {
                                  fontSize: "16px",
                                  fontFamily: "Kanit, sans-serif",
                                },
                              }}
                              {...field}
                              error={!!errors.first_name}
                              helperText={errors?.first_name?.message}
                              required
                              label="ชื่อ"
                              // autoFocus
                              id="firstname"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} className="mb-20">
                        <Controller
                          name="last_name"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              InputLabelProps={{
                                // shrink: true,
                                style: {
                                  fontSize: "16px",
                                  fontFamily: "Kanit, sans-serif",
                                },
                              }}
                              sx={{
                                ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                  {
                                    color: "red",
                                  },
                                "& .MuiInputBase-root": {
                                  fontSize: "16px",
                                  fontFamily: "Kanit, sans-serif",
                                },
                              }}
                              {...field}
                              required
                              error={!!errors.last_name}
                              helperText={errors?.last_name?.message}
                              label="นามสกุล"
                              // autoFocus
                              id="lastname"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={4} className="mb-20">
                        <Controller
                          name="company_name"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              InputLabelProps={{
                                // shrink: true,
                                style: {
                                  fontSize: "16px",
                                  fontFamily: "Kanit, sans-serif",
                                },
                              }}
                              sx={{
                                ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                  {
                                    color: "red",
                                  },
                                "& .MuiInputBase-root": {
                                  fontSize: "16px",
                                  fontFamily: "Kanit, sans-serif",
                                },
                              }}
                              {...field}
                              required
                              error={!!errors.company_name}
                              helperText={errors?.company_name?.message}
                              label="บริษัท"
                              // autoFocus
                              id="tel"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={4} className="mb-20">
                        <Controller
                          name="tel_no"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              InputLabelProps={{
                                // shrink: true,
                                style: {
                                  fontSize: "16px",
                                  fontFamily: "Kanit, sans-serif",
                                },
                              }}
                              sx={{
                                ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                  {
                                    color: "red",
                                  },
                                "& .MuiInputBase-root": {
                                  fontSize: "16px",
                                  fontFamily: "Kanit, sans-serif",
                                },
                              }}
                              {...field}
                              required
                              error={!!errors.tel_no}
                              helperText={errors?.tel_no?.message}
                              label="หมายเลขโทรศัพท์"
                              // autoFocus
                              id="tel"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={4} className="mb-20">
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              InputLabelProps={{
                                // shrink: true,
                                style: {
                                  fontSize: "16px",
                                  fontFamily: "Kanit, sans-serif",
                                },
                              }}
                              sx={{
                                ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                  {
                                    color: "red",
                                  },
                                "& .MuiInputBase-root": {
                                  fontSize: "16px",
                                  fontFamily: "Kanit, sans-serif",
                                },
                              }}
                              {...field}
                              required
                              error={!!errors.email}
                              helperText={errors?.email?.message}
                              label="อีเมล"
                              // autoFocus
                              id="email"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        borderTop: "1px solid #F0F0F0",
                        width: "95%",
                        margin: "0 auto",
                      }}
                      className="mb-12"
                    />
                  </Grid>
                  <Grid item xs={12} className="">
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={3} className="mb-20">
                        <Controller
                          name="license_plate"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              InputLabelProps={{
                                // shrink: true,
                                style: {
                                  fontSize: "16px",
                                  fontFamily: "Kanit, sans-serif",
                                },
                              }}
                              sx={{
                                ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                  {
                                    color: "red",
                                  },
                                "& .MuiInputBase-root": {
                                  fontSize: "16px",
                                  fontFamily: "Kanit, sans-serif",
                                },
                              }}
                              {...field}
                              value={field.value}
                              onChange={(e) => {
                                setValue("license_plate", e.target.value, {
                                  shouldDirty: true,
                                  shouldValidate: true,
                                });
                              }}
                              // required
                              error={!!errors.license_plate}
                              helperText={errors?.license_plate?.message}
                              label="ป้ายทะเบียน"
                              // autoFocus
                              id="lastname"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={3} className="mb-20">
                        <Controller
                          name="license_plate_province"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <Autocomplete
                              noOptionsText={"กรุณากรอกข้อมูลเพื่อค้นหา"}
                              getOptionLabel={(option) => {
                                return typeof option === "string"
                                  ? option
                                  : option?.name_th;
                              }}
                              options={
                                Array.isArray(provinces) ? provinces : []
                              }
                              filterSelectedOptions
                              value={value || null}
                              name="search"
                              onChange={(event, newValue) => {
                                onChange(newValue ? newValue.name_th : null);
                              }}
                              error={!!errors.license_plate_province}
                              helperText={
                                errors?.license_plate_province?.message
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="จังหวัด"
                                  variant="outlined"
                                  InputLabelProps={{
                                    style: {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                  }}
                                  sx={{
                                    ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                      {
                                        color: "red",
                                      },
                                    "& .MuiInputBase-root": {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                    ".MuiSvgIcon-root": {
                                      fontSize: "24px",
                                    },
                                  }}
                                  required={watch("license_plate")}
                                  error={!!errors.license_plate_province}
                                  helperText={
                                    errors?.license_plate_province?.message
                                  }
                                  fullWidth
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                  <Grid container alignItems="center">
                                    <Grid item xs>
                                      {option?.name_en}
                                      <Typography
                                        variant="body1"
                                        color="initial"
                                        sx={{
                                          fontSize: "16px !important",
                                          fontFamily:
                                            "Kanit, sans-serif !important",
                                        }}
                                      >
                                        {option?.name_th}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </li>
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={3} className="mb-20 ">
                        <Controller
                          name="vehicle_type_id"
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              noOptionsText={"กรุณากรอกข้อมูลเพื่อค้นหา"}
                              {...field}
                              options={vehicleType}
                              getOptionLabel={(option) => {
                                return typeof option === "string"
                                  ? option
                                  : option?.vehicle_type_name;
                              }}
                              value={field.value ? watch("vehicle_type") : null}
                              onChange={(_, data) => {
                                field.onChange(data?.id || "");
                                setValue("vehicle_type", data || null);
                              }}
                              error={!!errors.vehicle_type_id}
                              helperText={errors?.vehicle_type_id?.message}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="ประเภทรถ"
                                  variant="outlined"
                                  InputLabelProps={{
                                    style: {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                  }}
                                  required={watch("license_plate")}
                                  error={!!errors.vehicle_type_id}
                                  helperText={errors?.vehicle_type_id?.message}
                                  sx={{
                                    ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                      {
                                        color: "red",
                                      },
                                    "& .MuiInputBase-root": {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                    ".MuiSvgIcon-root": {
                                      fontSize: "24px",
                                    },
                                  }}
                                  fullWidth
                                />
                              )}
                              renderOption={(props, option) => {
                                return (
                                  <li {...props} key={option}>
                                    <Grid container alignItems="center">
                                      <Grid item xs>
                                        <Typography
                                          variant="body1"
                                          color="initial"
                                          sx={{
                                            fontSize: "16px !important",
                                            fontFamily:
                                              "Kanit, sans-serif !important",
                                          }}
                                        >
                                          {option?.vehicle_type_name}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </li>
                                );
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={3} className="mb-20">
                        <Controller
                          name="remark"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              InputLabelProps={{
                                // shrink: true,
                                style: {
                                  fontSize: "16px",
                                  fontFamily: "Kanit, sans-serif",
                                },
                              }}
                              sx={{
                                ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                  {
                                    color: "red",
                                  },
                                "& .MuiInputBase-root": {
                                  fontSize: "16px",
                                  fontFamily: "Kanit, sans-serif",
                                },
                              }}
                              {...field}
                              // required

                              error={!!errors.remark}
                              helperText={errors?.remark?.message}
                              label="หมายเหตุ"
                              // autoFocus
                              id="tel"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
          {followers.map((_, index) => (
            <Card
              sx={{ marginBottom: 3, border: 1, borderColor: "#E5E5E5" }}
              key={index + 1}
              ref={(el) => (followerRefs.current[index] = el)}
            >
              <CardContent
                sx={{
                  p: 2,
                  "&:last-child": {
                    paddingBottom: 2,
                  },
                }}
              >
                <Grid
                  container
                  alignItems="center"
                  onClick={() => toggleExpands(index + 1)}
                  sx={{ cursor: "pointer" }}
                >
                  <Grid item xs={8}>
                    <Typography className=" tx-left font-title1 kanit-semibold p-8">
                      {`ผู้ติดตาม คนที่ ${index + 1}`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", placeContent: "flex-end" }}
                  >
                    <IconButton
                      onClick={() => toggleExpands(index + 1)}
                      sx={{
                        ".MuiSvgIcon-root": {
                          fontSize: "24px",
                        },
                      }}
                    >
                      {!expands[index + 1] ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </IconButton>
                    <IconButton
                      onClick={() => removeFollower(index)}
                      sx={{
                        ".MuiSvgIcon-root": {
                          fontSize: "24px",
                          color: "red",
                        },
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Collapse in={expands[index + 1]}>
                  <Box className="pbt-8">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4} className="display-center">
                        <div class="container clearfix">
                          <div className="w-full">
                            <div className="flex flex-auto items-center pb-10 display-center">
                              <div className="border-box-img p-16">
                                <Typography className="text-position kanit-semibold font-14">
                                  รูปโปรไฟล์
                                </Typography>
                                <Controller
                                  control={control}
                                  name={`followers[${index}].image`}
                                  render={({ field: { onChange, value } }) => {
                                    // const handleImageUpload = (e) => {
                                    //   const file = e.target.files[0];
                                    //   if (file) {
                                    //     const reader = new FileReader();
                                    //     reader.onloadend = () => {
                                    //       onChange(reader.result);
                                    //     };
                                    //     reader.readAsDataURL(file);
                                    //   }
                                    // };

                                    const followerImageRef = createRef();

                                    return (
                                      <Box
                                        sx={{
                                          border:
                                            "1px solid rgba(0, 0, 0, 0.23)",
                                        }}
                                        className="relative flex items-center justify-center w-170 h-170 rounded-10 overflow-hidden"
                                      >
                                        <div className="absolute inset-0 bg-black bg-opacity-50" />
                                        <input
                                          accept="image/*"
                                          className="hidden"
                                          ref={followerImageRef}
                                          type="file"
                                          // onChange={handleImageUpload}
                                          onChange={(e) =>
                                            handleImageUpload(e, onChange)
                                          }
                                        />

                                        <div className="absolute inset-0 flex items-center justify-center z-20">
                                          <Button
                                            variant="contained"
                                            className="bt-upload"
                                            onClick={() =>
                                              followerImageRef.current.click()
                                            }
                                          >
                                            อัปโหลดรูป
                                          </Button>
                                        </div>

                                        <Avatar
                                          sx={{
                                            position: "absolute",
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                            borderRadius: "0px",
                                            color: "text.secondary",
                                          }}
                                          src={value || null}
                                          variant="rounded"
                                        />
                                      </Box>
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} className="mb-20">
                            <Controller
                              name={`followers[${index}].personal_id`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  InputLabelProps={{
                                    // shrink: true,
                                    style: {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                  }}
                                  sx={{
                                    ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                      {
                                        color: "red",
                                      },
                                    "& .MuiInputBase-root": {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                  }}
                                  {...field}
                                  error={
                                    !!errors.followers?.[index]?.personal_id
                                  }
                                  helperText={
                                    errors.followers?.[index]?.personal_id
                                      ?.message
                                  }
                                  required
                                  label="เลขบัตรประชาชน/พาสปอร์ต"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6} className="mb-20 ">
                            <Controller
                              name={`followers[${index}].first_name`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  InputLabelProps={{
                                    // shrink: true,
                                    style: {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                  }}
                                  sx={{
                                    ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                      {
                                        color: "red",
                                      },
                                    "& .MuiInputBase-root": {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                  }}
                                  {...field}
                                  error={
                                    !!errors.followers?.[index]?.first_name
                                  }
                                  helperText={
                                    errors.followers?.[index]?.first_name
                                      ?.message
                                  }
                                  required
                                  label="ชื่อ"
                                  // autoFocus
                                  id="firstname"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6} className="mb-20">
                            <Controller
                              name={`followers[${index}].last_name`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  InputLabelProps={{
                                    // shrink: true,
                                    style: {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                  }}
                                  sx={{
                                    ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                      {
                                        color: "red",
                                      },
                                    "& .MuiInputBase-root": {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                  }}
                                  {...field}
                                  required
                                  error={!!errors.followers?.[index]?.last_name}
                                  helperText={
                                    errors.followers?.[index]?.last_name
                                      ?.message
                                  }
                                  label="นามสกุล"
                                  // autoFocus
                                  id="lastname"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={6} md={4} className="mb-20">
                            <Controller
                              name={`followers[${index}].company_name`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  InputLabelProps={{
                                    // shrink: true,
                                    style: {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                  }}
                                  sx={{
                                    ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                      {
                                        color: "red",
                                      },
                                    "& .MuiInputBase-root": {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                  }}
                                  {...field}
                                  required
                                  error={
                                    !!errors.followers?.[index]?.company_name
                                  }
                                  helperText={
                                    errors.followers?.[index]?.company_name
                                      ?.message
                                  }
                                  label="บริษัท"
                                  // autoFocus
                                  id="tel"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6} md={4} className="mb-20">
                            <Controller
                              name={`followers[${index}].tel_no`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  InputLabelProps={{
                                    // shrink: true,
                                    style: {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                  }}
                                  sx={{
                                    ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                      {
                                        color: "red",
                                      },
                                    "& .MuiInputBase-root": {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                  }}
                                  {...field}
                                  required
                                  error={!!errors.followers?.[index]?.tel_no}
                                  helperText={
                                    errors.followers?.[index]?.tel_no?.message
                                  }
                                  label="หมายเลขโทรศัพท์"
                                  // autoFocus
                                  id="tel"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={4} className="mb-20">
                            <Controller
                              name={`followers[${index}].email`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  InputLabelProps={{
                                    // shrink: true,
                                    style: {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                  }}
                                  sx={{
                                    ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                      {
                                        color: "red",
                                      },
                                    "& .MuiInputBase-root": {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                  }}
                                  {...field}
                                  required
                                  error={!!errors.followers?.[index]?.email}
                                  helperText={
                                    errors.followers?.[index]?.email?.message
                                  }
                                  label="อีเมล"
                                  // autoFocus
                                  id="email"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <div
                          style={{
                            borderTop: "1px solid #F0F0F0",
                            width: "95%",
                            margin: "0 auto",
                          }}
                          className="mb-12"
                        />
                      </Grid>
                      <Grid item xs={12} className="">
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={3} className="mb-20">
                            <Controller
                              name={`followers[${index}].license_plate`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  InputLabelProps={{
                                    // shrink: true,
                                    style: {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                  }}
                                  sx={{
                                    ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                      {
                                        color: "red",
                                      },
                                    "& .MuiInputBase-root": {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                  }}
                                  {...field}
                                  // required
                                  error={
                                    !!errors.followers?.[index]?.license_plate
                                  }
                                  helperText={
                                    errors.followers?.[index]?.license_plate
                                      ?.message
                                  }
                                  label="ป้ายทะเบียน"
                                  // autoFocus
                                  id="lastname"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6} md={3} className="mb-20">
                            <Controller
                              name={`followers[${index}].license_plate_province`}
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <Autocomplete
                                  noOptionsText={"กรุณากรอกข้อมูลเพื่อค้นหา"}
                                  getOptionLabel={(option) => {
                                    return typeof option === "string"
                                      ? option
                                      : option?.name_th;
                                  }}
                                  options={
                                    Array.isArray(provinces) ? provinces : []
                                  }
                                  filterSelectedOptions
                                  value={value || null}
                                  name="search"
                                  onChange={(event, newValue) => {
                                    onChange(
                                      newValue ? newValue.name_th : null
                                    );
                                  }}
                                  error={
                                    !!errors?.followers?.[index]
                                      ?.license_plate_province
                                  }
                                  helperText={
                                    errors?.followers?.[index]
                                      ?.license_plate_province?.message
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="จังหวัด"
                                      variant="outlined"
                                      InputLabelProps={{
                                        style: {
                                          fontSize: "16px",
                                          fontFamily: "Kanit, sans-serif",
                                        },
                                      }}
                                      sx={{
                                        ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                          {
                                            color: "red",
                                          },
                                        "& .MuiInputBase-root": {
                                          fontSize: "16px",
                                          fontFamily: "Kanit, sans-serif",
                                        },
                                        ".MuiSvgIcon-root": {
                                          fontSize: "24px",
                                        },
                                      }}
                                      fullWidth
                                      required={watch(
                                        `followers[${index}].license_plate`
                                      )}
                                      error={
                                        !!errors?.followers?.[index]
                                          ?.license_plate_province
                                      }
                                      helperText={
                                        errors?.followers?.[index]
                                          ?.license_plate_province?.message
                                      }
                                    />
                                  )}
                                  renderOption={(props, option) => (
                                    <li {...props} key={option.id}>
                                      <Grid container alignItems="center">
                                        <Grid item xs>
                                          {option?.name_en}
                                          <Typography
                                            variant="body1"
                                            color="initial"
                                            sx={{
                                              fontSize: "16px !important",
                                              fontFamily:
                                                "Kanit, sans-serif !important",
                                            }}
                                          >
                                            {option?.name_th}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </li>
                                  )}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6} md={3} className="mb-20 ">
                            <Controller
                              name={`followers[${index}].vehicle_type_id`}
                              control={control}
                              render={({ field }) => (
                                <Autocomplete
                                  noOptionsText={"กรุณากรอกข้อมูลเพื่อค้นหา"}
                                  {...field}
                                  options={vehicleType}
                                  getOptionLabel={(option) => {
                                    return typeof option === "string"
                                      ? option
                                      : option?.vehicle_type_name;
                                  }}
                                  value={
                                    field.value
                                      ? watch(
                                          `followers[${index}].vehicle_type`
                                        )
                                      : null
                                  }
                                  onChange={(_, data) => {
                                    field.onChange(data?.id || "");
                                    setValue(
                                      `followers[${index}].vehicle_type`,
                                      data || null
                                    );
                                  }}
                                  error={
                                    !!errors?.followers?.[index]
                                      ?.vehicle_type_id
                                  }
                                  helperText={
                                    errors?.followers?.[index]?.vehicle_type_id
                                      ?.message
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="ประเภทรถ"
                                      variant="outlined"
                                      InputLabelProps={{
                                        style: {
                                          fontSize: "16px",
                                          fontFamily: "Kanit, sans-serif",
                                        },
                                      }}
                                      sx={{
                                        ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                          {
                                            color: "red",
                                          },
                                        "& .MuiInputBase-root": {
                                          fontSize: "16px",
                                          fontFamily: "Kanit, sans-serif",
                                        },
                                        ".MuiSvgIcon-root": {
                                          fontSize: "24px",
                                        },
                                      }}
                                      fullWidth
                                      required={watch(
                                        `followers[${index}].license_plate`
                                      )}
                                      error={
                                        !!errors?.followers?.[index]
                                          ?.vehicle_type_id
                                      }
                                      helperText={
                                        errors?.followers?.[index]
                                          ?.vehicle_type_id?.message
                                      }
                                    />
                                  )}
                                  renderOption={(props, option) => {
                                    return (
                                      <li {...props} key={option}>
                                        <Grid container alignItems="center">
                                          <Grid item xs>
                                            <Typography
                                              variant="body1"
                                              color="initial"
                                              sx={{
                                                fontSize: "16px !important",
                                                fontFamily:
                                                  "Kanit, sans-serif !important",
                                              }}
                                            >
                                              {option?.vehicle_type_name}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </li>
                                    );
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6} md={3} className="mb-20">
                            <Controller
                              name={`followers[${index}].remark`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  InputLabelProps={{
                                    // shrink: true,
                                    style: {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                  }}
                                  sx={{
                                    ".MuiInputLabel-asterisk.MuiInputLabel-asterisk":
                                      {
                                        color: "red",
                                      },
                                    "& .MuiInputBase-root": {
                                      fontSize: "16px",
                                      fontFamily: "Kanit, sans-serif",
                                    },
                                  }}
                                  {...field}
                                  // required

                                  error={!!errors.followers?.[index]?.remark}
                                  helperText={
                                    errors.followers?.[index]?.remark?.message
                                  }
                                  label="หมายเหตุ"
                                  // autoFocus
                                  id="tel"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Collapse>
              </CardContent>
            </Card>
          ))}
        </Box>
      </div>
    </>
  );
};

export default Step2;
